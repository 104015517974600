import React from 'react'
import { Link } from 'react-router-dom'
import ClientshotLogoWhite from '../assets/svgs/logo/Clientshot_logo_white.svg'
import Facebook from '../assets/svgs/socials/facebook.svg'
import Instagram from '../assets/svgs/socials/instagram.svg'
import Twitter from '../assets/svgs/socials/twitter.svg'
import Linkedin from '../assets/svgs/socials/linkedin.svg'
import Youtube from '../assets/svgs/socials/youtube.svg'

const Footer = () => {
    return (
        <>
            <div className='bg-black pl-6 md:pl-20 pt-20 pr-6 md:pr-20 pb-5'>
                <div className='container mx-auto'>
                    <div className="mb-4">
                        <Link to={`/`} >
                            <img src={ClientshotLogoWhite} alt="" style={{ height: 50 }} />
                        </Link>
                    </div>

                    <div className="md:flex justify-between gap-x-4 md:w-full">
                        <div className='mb-16 md:w-1/4'>
                            <p className='text-white base-text '>
                                Clientshot is a product of SeamHealth Group, a leading provider of healthcare technology solutions <br /><br />
                                Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers. Our goal is...
                                <Link to={`/about`} className="ml-2 primary-500">Read more</Link>
                            </p>

                        </div>
                        <div className="flex gap-12 md:gap-24 flex-col md:flex-row ">
                            <div>
                                <h6 className='heading-six text-white'>Company</h6>
                                <div className="mt-6">
                                    <Link to={`/`} className='text-link block mb-6' style={{ color: 'white' }}>
                                        Home
                                    </Link>
                                    <Link to={`/about`} className='text-link block mb-6' style={{ color: 'white' }}>
                                        About
                                    </Link>
                                    <Link to={`/contact`} className='text-link block mb-6' style={{ color: 'white' }}>
                                        Contact
                                    </Link>
                                    <Link to={`/pricing`} className='text-link block mb-6' style={{ color: 'white' }}>
                                        Pricing
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <h6 className='heading-six text-white'>Resources</h6>
                                <div className="mt-6">
                                    <a href={"https://clientshot.com/blog"} className='text-link block mb-6 ' style={{ color: 'white' }}>
                                        Blog
                                    </a>
                                    <a href={"https://www.youtube.com/@Clientshot"} target={'_blank'} className='text-link block mb-6 ' style={{ color: 'white' }}>
                                        Tutorials
                                    </a>

                                </div>
                            </div>

                            <div>
                                <h6 className='heading-six text-white'>Follow us</h6>
                                <div className="mt-6 flex gap-x-6">
                                    <a href={`https://www.facebook.com/clientshot1`} target={'_blank'} className='text-link social-icon block mb-6' style={{ color: 'white' }}>
                                        <img src={Facebook} alt="" />
                                    </a>
                                    <a href={`https://instagram.com/client_shot`} target={'_blank'} className='text-link social-icon block mb-6' style={{ color: 'white' }}>
                                        <img src={Instagram} alt="" />
                                    </a>
                                    <a href={`https://twitter.com/clientshot`} target={'_blank'} className='text-link social-icon block mb-6' style={{ color: 'white' }}>
                                        <img src={Twitter} alt="" />
                                    </a>
                                    <a href={`https://www.linkedin.com/company/clientshot/`} target={'_blank'} className='text-link social-icon block mb-6' style={{ color: 'white' }}>
                                        <img src={Linkedin} alt="" />
                                    </a>
                                    <a href={`https://www.youtube.com/@Clientshot`} target={'_blank'} className='text-link social-icon block mb-6' style={{ color: 'white' }}>
                                        <img src={Youtube} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className='text-white md:flex justify-between mt-20'>
                        <p className='small-text text-white text-center md:text-left'>
                            &copy; Copyright 2023 Clientshot <br className='md:hidden mb-8' /> | A product of <a href={"https://seamhealth.com"} target={'_blank'} className='primary-500'>SeamHealth Group</a>
                        </p>

                        <div className="flex gap-x-8 items-center justify-center mt-8 md:mt-0">
                            <a href={"https://app.clientshot.com/terms-and-conditons"} target={'_blank'} className='text-link small-text ' style={{ color: 'white' }}>
                                Terms of Service
                            </a>

                            <a href={"https://app.clientshot.com/privacy-policy"} target={'_blank'} className='text-link small-text ' style={{ color: 'white' }}>
                                Privacy Policy
                            </a>

                            <a href="/sitemap.xml" className='text-link small-text ' style={{ color: 'white' }}>
                                Sitemap
                            </a>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default Footer