import React, { useState } from 'react'
import Close from '../../assets/svgs/close.svg'
import Sent from '../../assets/svgs/sent.svg'
import Loader from '../../assets/images/gifs/loader.gif'

const ContactFeedback = ({ success, loader, closeModal, modal }) => {

  return (
    <>
        <div className={modal ? "overlay bg-black bg-opacity-70 h-screen w-screen z-[1000] top-0 fixed animate__animated animate__fadeIn animate__faster" : "overlay bg-black bg-opacity-70 h-screen w-screen z-[1000] top-0 hidden fixed animate__animated animate__fadeOut animate__faster"}>
            <div className="flex justify-center h-screen items-center">
                <div className='bg-white pb-3 md:w-2/5 md:rounded-3xl md:h-3/6 overflow-hidden animate__animated animate__fadeInUp animate__faster' >
                    <div className='my-4 px-4 flex justify-end md:w-full '>
                        <button onClick={() => closeModal()}>
                            <img src={Close} alt="" />
                        </button>
                    </div>
                    <div className="w-full flex justify-center py-4 px-4">
                        {
                            loader ? <img src={Loader} alt="" style={{width : 100}} /> 
                            : success ? (
                                <>
                                    <div>
                                        <div className="flex justify-center w-full">
                                            <img src={Sent} alt="" /> 
                                        </div>
                                        
                                        <h5 className="heading-five text-center">Message Sent!</h5>
                                        <p className="base-text text-center">
                                            Thank you for taking the time to fill out our contact form. 
                                            We have received your message and we’ll revert to you as soon as possible.
                                        </p>
                                    </div>
                                </>
                            ) : <></>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactFeedback