import React from 'react'
import Mockup04 from '../assets/images/mockup04.png'
import MissionIllustration from '../assets/svgs/mission-illustration.svg'
import Accordion from '../components/Accordion'
import LinkIcon from '../assets/svgs/external-link.svg'

const About = () => {
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
      {/* section one */}
      <div className=" mx-auto md:flex gap-8 mt-40 md:mt-24 px-4 md:px-8 items-center">
        <div className=' about-texts'>
          <h6 data-aos="fade-up" className="heading-six primary-500 desc">About</h6>
          <h3 data-aos="fade-up" className="heading-four">What is Clientshot?</h3>
          <p data-aos="fade-up" className="base-text">
            Clientshot is a product of <a href="https://seamhealth.com" target={'_blank'} rel={'noreferrer'} className='primary-500'>SeamHealth Group</a> , a leading provider of healthcare technology solutions.
            <br /> <br />
            Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers. With Clientshot, companies can collect feedback through various channels, including USSD, Web, and WhatsApp.
            <br /> <br />
            The built-in analytics allow businesses to track metrics such as <b> Net Promoter Score </b> and <b> Customer Satisfaction </b>over time and make informed decisions to improve the customer experience.
          </p>
        </div>

        <div data-aos="zoom-in-up" data-aos-delay="100" className='mt-8 md:mt-0'>
          <img src={Mockup04} alt="" />
        </div>
      </div>

      {/* section two */}
      <div className="flex flex-col md:flex-row mt-28 pr-8 gap-8 items-center section-three">

        <div data-aos="fade-right" data-aos-delay="100" className='order-last md:order-first'>
          <img src={MissionIllustration} alt="" />
        </div>

        <div className='pl-4 md:pl-0 md:w-3/5'>
          <h3 data-aos="fade-up" className="heading-three">Mission & Goals</h3>
          <p data-aos="fade-up" className="base-text">
            Our mission is to empower industries to better understand and meet the needs of their customers, resulting in enhanced customer satisfaction and positive outcomes.
            <br /> <br />
            Additionally, we aim to help businesses understand how their customers feel about their experiences and use that information to improve customer satisfaction and drive growth.
          </p>
        </div>
      </div>

      {/* section three */}
      <div className='mt-40'>
        <div className='bg-black px-4 md:p-20 md:px-8 py-20 jumbotron'>
          <div className=' mx-auto'>
            <h3 data-aos="fade-up" className="heading-three text-white">
              The Team
            </h3>
            <p data-aos="fade-up" className="base-text text-white">
              At SeamHealth Group, we believe that customer satisfaction is essential to the success of any business. Therefore, we've created Clientshot, a tool that makes it easy for companies of all sizes to collect and analyze customer feedback. Our team is dedicated to providing exceptional service and support, and we work closely with our clients to ensure they are getting the most out of our tool. With Clientshot, businesses can gain valuable insights into customer experiences and make data-driven decisions that enhance customer satisfaction and drive growth.
            </p>
            <div data-aos="fade-up" className='w-max'>
              <a href='https://www.seamhealth.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary btn-lg mt-6 flex gap-2'>
                Go to SeamHealth Group
                <img src={LinkIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* section four */}
      <div className=' mx-auto pt-40 mb-20 px-4 md:pr-8 section-six'>
        <h2 data-aos="fade-up" className="heading-four text-left md:text-center">Frequently Asked Questions</h2>

        <div className="md:flex gap-8 mt-12 md:mt-20">
          <div data-aos="fade-up" className='md:w-2/4'>
            <Accordion title="What is Clientshot?" desc="Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers." />
            <Accordion title="How does Clientshot measure customer satisfaction?" desc="Clientshot measures customer satisfaction by collecting feedback from customers through various channels, such as USSD, Web, and WhatsApp. The feedback is analyzed to calculate metrics such as Net Promoter Score and Customer Satisfaction." />
            <Accordion title="How does Clientshot collect customer feedback?" desc="Clientshot collects customer feedback through USSD, Web, and WhatsApp. The feedback can be collected through surveys, ratings, and other forms of interaction." />
            <Accordion title="How does Clientshot help businesses improve their customer satisfaction?" desc="Clientshot helps businesses improve their customer satisfaction by providing insights into how customers feel about their experience with the business. The feedback and analytics provided by Clientshot help businesses identify areas for improvement and make changes that will result in better customer satisfaction." />
          </div>

          <div data-aos="fade-up" className='md:w-2/4'>
            <Accordion title="What metrics does Clientshot track?" desc="Clientshot tracks metrics such as Net Promoter Score and Customer Satisfaction. The built-in analytics also provide insights into the impact of customer feedback on these metrics over time." />
            <Accordion title="Is there a cost for using Clientshot?" desc="Clientshot is currently free as it is in its early stage of development. We plan to introduce pricing plans in the future, but for now, users can enjoy the full functionality of the platform at no cost while we continue to refine and improve the service." />
            <Accordion title="How is customer data stored and protected by Clientshot?" desc="Customer data is stored securely by Clientshot and is protected by industry-standard security measures." />
            <Accordion title="How can I get started with Clientshot?" desc="To get started with Clientshot, you can click this link or contact us for more information." />
          </div>
        </div>

      </div>

    </>
  )
}

export default About