import React, { useState } from 'react'
import CheckIcon from '../../assets/svgs/check-icon-alt.svg'
import HelpIcon from '../../assets/svgs/help-circle.svg'
import StarterPlanAccordion from '../../components/MiniAccordion'

const StarterSubPricingTable = ({ duration, currency, dollar, symbol }) => {

    
    let monthPrice = 6500;
    let yearPrice = 5000;
    const [fmtMonthPrice, setFmtMonthPrice] = useState([])


    const [price, setPrice] = useState(0)

    React.useEffect(() => {
      let i = true

      // check that duration is monthly or yearly in ngn

      if(duration == 0 && currency == 0){
        
        setPrice(monthPrice)
        }else if (duration == 1 && currency == 0){
            setPrice(yearPrice)
            
        }

        // check that duration is monthly or yearly in usd

        if(duration == 0 && currency == 1){
            setPrice(monthPrice / dollar)
        }else if(duration == 1 && currency == 1){
            setPrice(yearPrice/ dollar)
        }

      return () => {
        i = false
      }
    })
   
  return (
    <div data-aos="zoom-in-up">
        <div  className='p-6 pricing-table h-full'>
            <div className="mb-2">
                <h5 className="primary-500 heading-six mb-1">
                    Starter
                </h5>
                <h4 className="heading-four secondary-500">{duration == 0 ? symbol + price.toLocaleString("en-US") : symbol + price.toLocaleString("en-US")} <span className='heading-six secondary-300'>/user/mo</span> </h4>
                {/* <span className="badge small-text secondary-300">
                    No setup fee
                </span> */}
                <p className="base-text secondary-200">Suitable for small businesses</p>
                <div className='flex flex-col gap-2 mt-2'>
                    <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary btn-sm w-full text-center'>
                        Get Started
                    </a>

                </div>
                {duration == 1 && <span className="badge discount-tag">Save 23%</span>}
            </div>
            {/* features */}
            <div className="flex flex-col justify-between h-full">
                <div className='flex flex-col gap-2 mb-16'>
                {/* <div className="flex gap-1 small-text primary-500 ">
                    <img src={CheckIcon} alt="" />
                    N50 per interaction
                </div> */}
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Reporting and analytics
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Activity logs
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Inbuilt CRM
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Import contacts
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Custom bot name
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    CSV export
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Email & chat support
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Feedback collection through the web channel
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Maximum of 2 users
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Priority customer support
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    500 units for bulk SMS 
                    (N1000 for additional 100 Units)
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    1 Workspace
                </div>
                <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Mobile app
                    </div>
                </div>
                {/* <div className='flex flex-col gap-2'>
                    <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary btn-sm w-full text-center'>
                        Get Started
                    </a>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default StarterSubPricingTable