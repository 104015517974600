import React from 'react'
import { Link } from 'react-router-dom'
import CheckIcon from '../assets/svgs/check-icon-alt.svg'
import IHVN from '../assets/images/ihvn-logo.png'
import SEAMHEALTH from '../assets/images/seamhealth-logo.png'
import VALUEJET from '../assets/images/valuejet-logo.png'

import StarterPlanAccordion from './MiniAccordion'

const EnterprisePricingTable = () => {
  return (
    <div data-aos="zoom-in-up" data-aos-easing="ease-out"  className='p-6 pricing-table-wide w-full md:flex'>
        <div className='md:w-2/4'>
            <div className="mb-8">
                <h5 className="primary-500 heading-six mb-4">
                    Enterprise
                </h5>
            
                <p className="base-text secondary-300">Touch base with us to explore a tailored solution designed exclusively for your unique enterprise requirements.</p>
                
            </div>
            <div className='flex flex-col gap-2 mb-6'>
                    <Link to={'/contact'} className='btn btn-primary btn-sm md:w-3/5 text-center'>
                        Contact us
                    </Link>
                </div>
            {/* features */}
            <div className="md:flex flex-col justify-end h-2/4 hidden">
                <div className='flex flex-col gap-2 mb-16'>
                    <div className="md:flex gap-1 small-text  secondary-300">
                        #1 digital customer satisfaction tool
                    </div>
            
                </div>
            
            </div>
        </div>
        <div className='md:w-2/4'>
            
            <p className="base-text secondary-400 font-bold mb-6">
            Personalized Pricing to Suit Your Needs
            </p>

            <div className='flex flex-col gap-3 mb-16'>
                <div className="flex gap-1 small-text secondary-300 ">
                    <img src={CheckIcon} alt="" />
                    Integration with CRM systems
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    API access for data integration
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Custom feedback flow
                </div>
                <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Unlimited users & workspaces
                </div>

                
                {/* <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Protect your operations with enhanced ....
                </div> */}
        
            </div>

            <div>
                <div className="flex flex-col md:items-end">
                    <p className="small-text secondary-300">Companies we’ve worked with and many more...</p>
                    <div className="flex gap-4 mt-3.5">
                        <img src={IHVN} alt="" /> 
                        <img src={VALUEJET} alt="" /> 
                        <img src={SEAMHEALTH} alt="" /> 
                    </div>
                </div>
            </div>

            <div className="md:hidden gap-1 small-text mt-4 secondary-300">
                #1 digital customer satisfaction tool
            </div>
        </div>
    </div>
  )
}

export default EnterprisePricingTable