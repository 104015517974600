import React from 'react';
import { createRoot } from "react-dom/client"
import './assets/css/style.css'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Error from './assets/error/Error';
import Layout from './layouts/Layout';
import Pricing from './pages/Pricing';
import CompanyLoaderPage from './pages/CompanyPageLoader';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "contact",
        element: <Contact />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "pricing",
        element: <Pricing />
      },

    ],
  },
  {
    path: "/:companyId",
    element: <CompanyLoaderPage />
  },
]);


createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);