import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Outlet } from 'react-router-dom'
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer'
import Header from './Header'
import { LocationContext } from '../components/contexts/LocationContext';

AOS.init();

const Layout = () => {

  const [currency, setCurrency] = useState(0) //switch between ngn and usd 
  const [manualswitch, setManualSwitch] = useState(false) //toggle manual switch to override auto switch
  const [test, setTestValue] = useState("Hello")

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    //get user location for currency localization

    var config = {
      method: 'get',
      // url: process.env.REACT_APP_GEOIP_LOCATOR,
      url: 'https://api.geoapify.com/v1/ipinfo?&apiKey=f32e664c7ceb4b129a90ece6b4c9b058',
      headers: {}
  };

  axios(config)
      .then(function (response) {
          // console.log(response.data);
          if (response.data.country.currency !== "NGN" && !manualswitch) { //if user's location is not nigeria and manual switch is not toggled
              setCurrency(1)
          }

      })
      .catch(function (error) {
          console.log(error);
      });

  return (
    <>
      <LocationContext.Provider value={{currency, setCurrency, setManualSwitch}}>
        <Header />
        <Outlet />
        <Footer />
      </LocationContext.Provider>
    </>
  )
}

export default Layout