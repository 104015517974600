import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

const Error = () => {
  // const error = useRouteError();
  // console.error(error);

  return (
    <>
      <Header />
      <div className="flex w-full justify-center my-36 px-4 md:px-0 md:my-24">
        <div className="error flex flex-col items-center text-center">
          <h3 className="heading-three">
            Oops!!
          </h3>

          <h1 className='primary-500 font-bold' style={{ fontSize: 149.06 }}>
            404
          </h1>

          <h3 className="heading-three">
            Page Not Found
          </h3>

          <p className="error-desc">
            Page does not exist, or was removed! We suggest you go back home
          </p>

          <Link
            to={`/`}
            className='btn btn-primary btn-lg mt-6 flex gap-2 w-max px-20'
            style={{ paddingLeft: 56, paddingRight: 56 }} >
            Go Home
          </Link>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Error