import { useState } from 'react'
import React from 'react'
import CheckIcon from '../../assets/svgs/check-icon-alt.svg'
import StarterPlanAccordion from '../../components/MiniAccordion'

const ProSubPricingTable = ({ duration, currency, dollar, symbol }) => {

    let monthPrice = 25200;
    let yearPrice = 15120;

    const [price, setPrice] = useState(0)

    React.useEffect(() => {
      let i = true

      // check that duration is monthly or yearly in ngn

      if(duration == 0 && currency == 0){
        setPrice(monthPrice)
        }else if (duration == 1 && currency == 0){
            setPrice(yearPrice)
        }

        // check that duration is monthly or yearly in usd

        if(duration == 0 && currency == 1){
            setPrice(monthPrice / dollar)
        }else if(duration == 1 && currency == 1){
            setPrice(yearPrice/ dollar)
        }

      return () => {
        i = false
      }
    })

  return (
    <div data-aos="zoom-in-up" data-aos-delay="200">
        <div  className='p-6 pricing-table mt-8 md:mt-0 h-full'>
            <div className="mb-2">
                <h5 className="primary-500 heading-six mb-1">
                    Pro
                </h5>
                <h4 className="heading-four secondary-500">{duration == 0 ? symbol + price.toLocaleString("en-US") : symbol + price.toLocaleString("en-US")} <span className='heading-six secondary-300'>/user/mo</span> </h4>
                {/* <span className="badge small-text secondary-300">
                    One-time setup fee
                </span> */}
                <p className="base-text secondary-200">Built for peak efficiency</p>
                <div className='flex flex-col gap-2 mt-2'>
                    <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary btn-sm w-full text-center'>
                        Get Started
                    </a>
                </div>
                {duration == 1 && <span className="badge discount-tag">Save 40%</span>}
            </div>
            {/* features */}
            <div className="flex flex-col justify-between h-full">
                <div className='flex flex-col gap-2 mb-16'>
                    <div className="flex gap-1">
                    <StarterPlanAccordion />
                    </div>
        
                    <div className="flex gap-1 small-text secondary-400 " style={{fontWeight : 'bold'}}>
                        Additional features
                    </div>
                    {/* <div className="flex gap-1 small-text primary-500 ">
                        <img src={CheckIcon} alt="" />
                        N35 per interaction
                    </div> */}
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Feedback collection through the web channel and WhatsApp.
                    </div>
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Department
                    </div>
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Maximum of 10 users
                    </div>
                    
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        5 workspaces
                    </div>
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        5,000 units for bulk SMS 
                        (N1000 for additional 100 Units)
                    </div>
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Custom role permissions
                    </div>
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Custom SMS templates
                    </div>
                    
                    {/* <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Integration with CRM systems
                    </div>
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        API access for data integration
                    </div> */}
                    <div className="flex gap-1 small-text secondary-300">
                        <img src={CheckIcon} alt="" />
                        Dedicated account manager
                    </div>
                </div>
                {/* <div className='flex flex-col gap-2'>
                    <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary btn-sm w-full text-center'>
                        Get Started
                    </a>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default ProSubPricingTable