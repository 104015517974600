import React, { useState } from 'react'
import Close from '../../assets/svgs/close.svg'
import IndustryIcon from '../../assets/svgs/industry.svg'
import UserIcon from '../../assets/svgs/user.svg'
import MailIcon from '../../assets/svgs/mail.svg'
import CheckIcon from '../../assets/svgs/check-icon.svg'
import Loader from '../../assets/images/gifs/loader.gif'
import axios from 'axios';
import { Link } from 'react-router-dom'

const Waitlist = ({ modal = false, closeModal }) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState();
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false)

    // error states
    const [nameError, setnameError] = useState()
    const [emailError, setemailError] = useState()
    const [phoneError, setphoneError] = useState()
    const [messageError, setmessageError] = useState()
    const [companyError, setcompanyError] = useState()
    const [mainError, setmainError] = useState(false)

    const getForm = async (e) =>{
        e.preventDefault();

        setLoader(true) 

        try {
            let resp = await axios.post(process.env.REACT_APP_WAITLIST, {
                'fullName' : name,
                'email' : email,
                'companyName' : company,
                'phoneNumber' : phone
            })    
            
            setLoader(false)
            // console.log(resp.data.message)
            setSuccess(true)

        
        } catch (error) {
            setLoader(false)
            setSuccess(false)
            let [response] = error.response.data.errors

            // console.log(response)
            if(response.field === "fullName"){
                setnameError(response)
            }if(response.field === "email"){
                setemailError(response)
            }if(response.field === "phoneNumber"){
                setphoneError(response)
            }if(response.field === "companyName"){
                setcompanyError(response)
            }

            if(error.response.data.statusCode === 500){
                setmainError(true)
                setTimeout(()=>{
                    setmainError(false)
                }, 5000)
            }
        }


    }

    // close and reset waitlist modal
    const closeandreset = () =>{
        closeModal();
        setLoader(false)
        setSuccess(false)
    }

  return (
    <>
        <div className={modal ? 'overlay bg-black bg-opacity-70 h-screen w-screen z-[1000] top-0 fixed animate__animated animate__fadeIn animate__faster' : 'overlay bg-black bg-opacity-50 h-screen w-screen z-[1000] hidden top-0 fixed'} >
            <div className="flex justify-center h-screen items-center">
                <div className='md:flex bg-white md:w-3/5 md:rounded-3xl h-screen md:h-5/6 overflow-hidden animate__animated animate__fadeInUp animate__faster' >
                    
                    <div className='w-2/5 hidden md:block side-graphics'>
                        <div className="side-hexagon h-full w-full">
                            <div className="bg-group-icon h-full w-full">

                            </div>
                        </div>
                    </div>

                    
                    {/* Form is here! 🌝 */}  
                    <div className='md:w-3/5'>
                        <div className='my-4 px-4 flex justify-end w-full'>
                            <button>
                                <img src={Close} alt="" onClick={() => closeandreset()} />
                            </button>
                        </div>

                        {/* Loading */}
                        { loader ? (
                            <div className="flex w-screen md:w-full md:h-3/5 items-center">
                                <div className="w-full flex justify-center">
                                    <div className='text-center'>
                                        <img src={Loader} alt="" style={{width : 100}} />
                                        {/* Cooking... */}
                                    </div>
                                </div>
                            </div>) 
                       
                       : success ?
                            //    Success message
                        (
                            <div className='px-6 md:px-10 mt-10'>
                                <h5 className="heading-six text-center">
                                    Thank you for your interest! <br /> You have been added to our waitlist.
                                </h5>
                                <p className="base-text text-center mt-3">
                                    We appreciate your interest and look forward to the opportunity to serve you
                                </p>

                                <div className="my-4 flex justify-center">
                                    <img src={CheckIcon} alt="" />
                                </div>

                                <p className="base-text text-center mt-3">
                                    Please feel free to reach out to us if you have any questions or concerns.
                                </p>

                                <div className="mt-3 flex justify-center">
                                    <Link className="btn btn-secondary" to={`/contact`}  >
                                        Contact us
                                    </Link>
                                </div>
                            </div>
                        )
                       
                       : (
                        // Form
                        <div className="px-6 md:px-10">
                            <h5 className="heading-five">Get Started</h5>
                            <p className="base-text secondary-300">
                            Transform your customer feedback into actionable insights -- Be the first to know when we launch 
                            </p>
                            {mainError ? (
                                <div className='p-3 my-2 rounded-lg' style={{backgroundColor : '#FFD6D6'}}>
                                    <p className='base-text' style={{color : 'red'}}>Please Check your internet connection and try again</p>
                                </div>
                            ): <></>}
                            <div className='mt-4 md:mt-8'>
                            <form onSubmit={getForm}  method='POST'>
                                    <div className='w-full'>
                                        <span className='input-icon'><img src={UserIcon} alt="" /></span>
                                        <input type="text" onChange={(e) => setName(e.target.value) } className={nameError != null ? "form-input w-full prepend input-error" : "form-input w-full prepend"} placeholder='Name' required /> 
                                        {nameError != null ? <p className='text-red small-text'>{nameError.message}</p>: <></>}
                                    </div>
                                    
                                    <div className='w-full mt-6 md:mt-4'>
                                        <span className='input-icon'><img src={MailIcon} alt="" /></span>
                                        <input type="email" onChange={(e) => setEmail(e.target.value) } className={emailError != null ? "form-input w-full prepend input-error" : "form-input w-full prepend"} placeholder='Email Address' required/> 
                                        {emailError != null ? <p className='text-red small-text'>{emailError.message}</p>: <></>}
                                    </div>
                                    
                                    <div className="w-full mt-6 md:mt-4">
                                        <input type="number" onChange={(e) => setPhone(e.target.value) } className={phoneError != null ? "form-input w-full input-error" : "form-input w-full "} placeholder='Phone Number' required />
                                        {phoneError != null ? <p className='text-red small-text'>{phoneError.message}</p>: <></>}
                                    </div>

                                    <div className="w-full mt-6 md:mt-4">
                                        <span className="input-icon"><img src={IndustryIcon} alt="" /></span>
                                        <input type="text" onChange={(e) => setCompany(e.target.value) } className={companyError != null ? "form-input w-full prepend input-error" : "form-input w-full prepend"} placeholder='Name of Company'  required />
                                        {companyError != null ? <p className='text-red small-text'>{companyError.message}</p>: <></>}
                                    </div>

                                

                                <div className="mt-6">
                                    <button className="w-full btn btn-primary">
                                        Join Waitlist
                                    </button>
                                </div>
                            </form>
                            </div>
                        </div>
                        )}
                    </div>
                    
                    {/* mobile footer */}
                    <div className='bottom-0 absolute w-full md:hidden h-24' style={{backgroundColor : '#624B93'}}>
                        <div className="h-full mobile-hexagon-bg">
                            <div className="modal-footer h-full">
                                <div className="flex justify-end h-full items-end px-6 text-white">
                                    &copy; Copyright 2023 Clientshot
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Waitlist