import React from 'react'
import ChevronDown from '../assets/svgs/chevron-down.svg'
import ChevronUp from '../assets/svgs/chevron-up.svg'

const Accordion = ({ title, desc }) => {

    const [toggle, setToggle] = React.useState(false);

  return (
    <>
        <div className='accordion p-3 md:p-7'>
            <button className='flex justify-between px-0 w-full' onClick={() => setToggle(!toggle)}> 
                <p className={!toggle ? 'base-text-m text-left' : 'base-text-m text-left primary-500'}>
                    {title}
                </p>

                {
                    !toggle ? <img src={ChevronDown} alt="" />
                    : <img src={ChevronUp} alt="" />
                }
                
            </button>

            {/* content */}

            <div className={toggle ? 'mt-8' : 'mt-8 hidden'}>
                {desc}
            </div>
        </div>
        
    </>
  )
}

export default Accordion