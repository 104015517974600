import React, { useEffect, useState } from 'react'
import CheckIcon from '../assets/svgs/check-icon-alt.svg'
import ChevronRight2 from '../assets/svgs/chevron-right2.svg';

const MiniAccordion = () => {
  
  const [toggle, setToggle] = useState(false);

  return (
    <>
        <div className={'mini-accordion w-full overflow-hidden'} onClick={() => {setToggle(!toggle)}}>
          <div className='primary-500 flex justify-between items-center w-full' style={{fontSize : 14}}>
              Everything in Starter Plan
              <img src={ChevronRight2} className={toggle ? 'rotate' : ''} alt="" />
          </div>

          <div className={toggle ? "" : "hidden"}>
            <div className="flex flex-col gap-2 mt-3">
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Reporting and analytics
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Activity logs
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Inbuilt CRM
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Import contacts
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Custom bot name
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  CSV export
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Email & chat support
              </div>
              <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Priority customer support
              </div>
              {/* <div className="flex gap-1 small-text secondary-300">
                  <img src={CheckIcon} alt="" />
                  Bulk SMS (5 Naira per SMS)
              </div> */}
              <div className="flex gap-1 small-text secondary-300">
                    <img src={CheckIcon} alt="" />
                    Mobile app
                </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default MiniAccordion